
import { onMounted, ref } from "vue";
import { useStore } from "vuex";
import { chevronBack } from "ionicons/icons";
import translate from "@/views/translate";
import {getImageDataUri} from "@/utilities/Compatibility";

export default {
    name: "Privacy",
    components: {
    },
    async ionViewWillEnter() {
        const currentView = this as any;
        currentView.returnRoute = currentView.$store.state.prevPage;
        currentView.$store.dispatch("setHeaderImage", getImageDataUri(currentView.t("messages.coachPage.headerImage")));
        currentView.$store.dispatch("setHeaderTitle", '');
    },
    setup() {
        const { t } = translate();
        const store = useStore();
        const returnRoute = ref('');
        const contentImage = ref('');
        const contentText = ref('');
        
        onMounted(async () => {
            const language = store.getters.getLocaleMessages();
            contentText.value = language.coachPage.contentText;
            contentImage.value = getImageDataUri(language.coachPage.contentImage);
        });
        
        return {
            t,
            store,
            returnRoute,
            contentImage,
            contentText,
            chevronBack
        };
    },
};
